import {ORIENTATION} from "../../utils/util";

export function MainPageRect({text, orientation, hovered, setHovered}) {
    let mt = "mt-[2rem]"
    let mr = "mr-[2rem]"
    let mb = "mb-[2rem]"
    let ml = "ml-[2rem]"
    let size = "h-[20rem] w-[20rem]"

    if (hovered != null) {
        size = hovered === orientation ? "h-[26rem] w-[26rem]" : "h-[14rem] w-[14rem]";
    }

    if (hovered === ORIENTATION.TOP_LEFT) {
        if (orientation === ORIENTATION.TOP_RIGHT) {
            mt = "mt-[8rem]"
        } else if (orientation === ORIENTATION.BOTTOM_LEFT) {
            ml = "ml-[8rem]"
        } else if (orientation === ORIENTATION.BOTTOM_RIGHT) {
            ml = "ml-[8rem]"
        }
    } else if (hovered === ORIENTATION.TOP_RIGHT) {
        if (orientation === ORIENTATION.TOP_LEFT) {
            mt = "mt-[8rem]"
        } else if (orientation === ORIENTATION.BOTTOM_LEFT) {
            mr = "mr-[8rem]"
        } else if (orientation === ORIENTATION.BOTTOM_RIGHT) {
            mr = "mr-[8rem]"
        }
    } else if (hovered === ORIENTATION.BOTTOM_LEFT) {
        if (orientation === ORIENTATION.BOTTOM_RIGHT) {
            mt = "mt-[8rem]"
        } else if (orientation === ORIENTATION.TOP_LEFT) {
            ml = "ml-[8rem]"
        } else if (orientation === ORIENTATION.TOP_RIGHT) {
            ml = "ml-[8rem]"
        }
    } else if (hovered === ORIENTATION.BOTTOM_RIGHT) {
        if (orientation === ORIENTATION.BOTTOM_LEFT) {
            mt = "mt-[8rem]"
        } else if (orientation === ORIENTATION.TOP_LEFT) {
            mr = "mr-[8rem]"
        } else if (orientation === ORIENTATION.TOP_RIGHT) {
            mr = "mr-[8rem]"
        }
    }

    return <div
        className={`${size} ${mt} ${mr} ${mb} ${ml} bg-gray-600 rounded-2xl transition-all duration-1000 ease-in-out flex shadow-md`}
        onMouseEnter={() => {
            setHovered(orientation)
        }}
        onMouseLeave={() => {
            setHovered(null)
        }}

    >
        <div className={"m-auto text-2xl text-white"}>{text}</div>
    </div>;
}
