import {SIZE_NORMAL} from "../utils/util";
import {Link} from "react-router-dom";

export function LinkItem({to, children}) {
    return <Link
        to={to}
        className={`${SIZE_NORMAL} m-[2rem] bg-gray-600 rounded-2xl transition-all duration-1000 ease-in-out flex shadow-md`}
    >
        <div className={"m-auto text-2xl text-white"}>{children}</div>
    </Link>;
}
