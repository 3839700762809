import {Link} from "react-router-dom";

export function NavItem({children, to}) {
    return <Link
        to={to}
        className={"p-1 my-2 mx-2 rounded-3xl hover:bg-zinc-600 hover:rounded transition-all duration-500"}
    >
        {children}
    </Link>
}
