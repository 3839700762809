import './App.css';
import {NavMenu} from "./components/mainroutes/NavMenu";
import {BrowserRouter} from "react-router-dom";
import {MainRoutes} from "./components/mainroutes/MainRoutes";

function App() {

    return (
        <BrowserRouter>
            <NavMenu/>
            <MainRoutes/>
        </BrowserRouter>
    );
}

export default App;
