import {useState} from "react";
import {ORIENTATION} from "../../utils/util";
import {MainPageRect} from "./MainPageRect";

export function MainPageFourRectangles() {
    const [hovered, setHovered] = useState(null);

    let backgroundColor = "bg-pos-center"
    if (hovered === ORIENTATION.TOP_LEFT) {
        backgroundColor = "bg-gradient-to-tl from-slate-900 via-slate-900 to-orange-800 bg-pos-tl"
    } else if (hovered === ORIENTATION.TOP_RIGHT) {
        backgroundColor = "bg-gradient-to-tr from-slate-900 via-slate-900 to-lime-800 bg-pos-tr"
    } else if (hovered === ORIENTATION.BOTTOM_LEFT) {
        backgroundColor = "bg-gradient-to-bl from-slate-900 via-slate-900 to-violet-800 bg-pos-bl"
    } else if (hovered === ORIENTATION.BOTTOM_RIGHT) {
        backgroundColor = "bg-gradient-to-br from-slate-900 via-slate-900 to-purple-800 bg-pos-br"
    }

    return (
        <div
            className={`bg-slate-900 bg-size-200 ${backgroundColor} h-screen transition-all duration-1000 ease-in-out`}>
            <div className={"container mx-auto"}>
                <div className={"flex flex-wrap justify-center w-[48rem] mx-auto"}>
                    <MainPageRect text="JAVA" orientation={ORIENTATION.TOP_LEFT} hovered={hovered}
                                  setHovered={setHovered}/>
                    <MainPageRect text="SPRING" orientation={ORIENTATION.TOP_RIGHT} hovered={hovered}
                                  setHovered={setHovered}/>
                    <MainPageRect text="REACT" orientation={ORIENTATION.BOTTOM_LEFT} hovered={hovered}
                                  setHovered={setHovered}/>
                    <MainPageRect text="KOTLIN" orientation={ORIENTATION.BOTTOM_RIGHT} hovered={hovered}
                                  setHovered={setHovered}/>
                </div>
            </div>
        </div>
    )
}
