import {LinkItem} from "../../libs/LinkItem";

export function SandboxOverview() {

    return (
        <div className={"container mx-auto flex flex-wrap justify-center"}>
            <LinkItem to={"foursquares"}>4 Quadrate</LinkItem>
            <LinkItem to={"colortransition"}>Color Transition</LinkItem>
        </div>
    )
}
