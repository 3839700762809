import {SIZE_NORMAL} from "../utils/util";

export function Rect({children, onMouseEnter, onMouseLeave}) {
    return <div
        className={`${SIZE_NORMAL} m-[2rem] bg-gray-600 rounded-2xl flex shadow-md`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        {children}
    </div>;
}
