import {Route, Routes} from "react-router-dom";
import {SandboxOverview} from "./SandboxOverview";
import {FourSquaresPages} from "../foursquares/FourSquaresPages";
import {ColorTransitionPage} from "../colortransition/ColorTransitionPage";

export function SandboxRoutes() {

    return (
        <Routes path={"/sandbox"}>
            <Route path={"/"} element={<SandboxOverview/>}/>
            <Route path={"/foursquares"} element={<FourSquaresPages/>}/>
            <Route path={"/colortransition"} element={<ColorTransitionPage/>}/>
        </Routes>
    )
}
