import {useState} from "react";
import {ORIENTATION} from "../../utils/util";
import {RectElement} from "../rectangles/RectElement";

export function FourSquares() {
    const [hovered, setHovered] = useState(null);

    return (
        <div className={"container mx-auto"}>
            <div className={"flex flex-wrap justify-center w-[48rem] mx-auto"}>
                <RectElement text="CORNER" orientation={ORIENTATION.TOP_LEFT} hovered={hovered}
                             setHovered={setHovered}/>
                <RectElement text="CENTERED" orientation={ORIENTATION.TOP_RIGHT} hovered={hovered}
                             setHovered={setHovered}/>
                <RectElement text="DEFORMED" orientation={ORIENTATION.BOTTOM_LEFT} hovered={hovered}
                             setHovered={setHovered}/>
                <RectElement text="ARROWED" orientation={ORIENTATION.BOTTOM_RIGHT} hovered={hovered}
                             setHovered={setHovered}/>
            </div>
        </div>
    )
}
