import {NavBar} from "../../libs/NavBar";
import {NavItem} from "../../libs/NavItem";

export function NavMenu() {
    return <NavBar>
        <NavItem to="/">Über mich</NavItem>
        {/*<NavItem to="/themen">Themen</NavItem>*/}
        {/*<NavItem to="/angebot">Angebot</NavItem>*/}
        <NavItem to="/sandbox">Sandbox</NavItem>
    </NavBar>;
}
