import {Route, Routes} from "react-router-dom";
import {SandboxRoutes} from "../sandbox/SandboxRoutes";
import {MainPageFourRectangles} from "../mainpage/MainPageFourRectangles";

export function MainRoutes() {
    return (
        <div className={"bg-slate-900 h-screen"}>
            <Routes>
                <Route path="/" element={<MainPageFourRectangles/>}/>
                <Route path="/sandbox/*" element={<SandboxRoutes/>}/>
            </Routes>
        </div>

    )
}
