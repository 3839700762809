import {BackArrow} from "../../libs/BackArrow";
import {ColorTransition} from "./ColorTransition";

export function ColorTransitionPage() {
    return (
        <>
            <BackArrow/>
            <ColorTransition/>
        </>
    )
}
