import {useState} from "react";
import {ORIENTATION} from "../../utils/util";
import {TextRect} from "../../libs/TextRect";

export function ColorTransition() {
    const [hovered, setHovered] = useState(null);

    let backgroundColor = "bg-pos-center"
    if (hovered === ORIENTATION.TOP_LEFT) {
        backgroundColor = "bg-gradient-to-tl from-slate-900 via-slate-900 to-orange-800 bg-pos-tl"
    } else if (hovered === ORIENTATION.TOP_RIGHT) {
        backgroundColor = "bg-gradient-to-tr from-slate-900 via-slate-900 to-lime-800 bg-pos-tr"
    } else if (hovered === ORIENTATION.BOTTOM_LEFT) {
        backgroundColor = "bg-gradient-to-bl from-slate-900 via-slate-900 to-red-800 bg-pos-bl"
    } else if (hovered === ORIENTATION.BOTTOM_RIGHT) {
        backgroundColor = "bg-gradient-to-br from-slate-900 via-slate-900 to-purple-800 bg-pos-br"
    }

    const hoverProps = (orientation) => ({
        onMouseEnter: () => setHovered(orientation),
        onMouseLeave: () => setHovered(null)
    })

    return (
        <div
            className={`bg-slate-900 bg-size-200 ${backgroundColor} h-screen transition-all duration-1000 ease-in-out`}>
            <div className={"container mx-auto"}>
                <div className={"flex flex-wrap justify-center w-[48rem] mx-auto"}>
                    <TextRect text={"ORANGE"} {...hoverProps(ORIENTATION.TOP_LEFT)}></TextRect>
                    <TextRect text={"GREEN"} {...hoverProps(ORIENTATION.TOP_RIGHT)}></TextRect>
                    <TextRect text={"RED"} {...hoverProps(ORIENTATION.BOTTOM_LEFT)}></TextRect>
                    <TextRect text={"PURPLE"} {...hoverProps(ORIENTATION.BOTTOM_RIGHT)}></TextRect>
                </div>
            </div>
        </div>
    )
}
