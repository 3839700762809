import {ArrowLeftIcon} from "@heroicons/react/solid";
import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom'

export function BackArrow() {
    const [opening, setOpening] = useState(true);

    const navigate = useNavigate()

    useEffect(() => {
        setOpening(false)
    }, [])

    const size = opening ? "w-0 h-0" : "w-14 h-14"
    return (
        <div
            className={`fixed top-20 left-4 ${size} rounded-full hover:bg-slate-700 transition-all duration-300`}
            onClick={() => navigate(-1)}
        >
            <ArrowLeftIcon className={"text-white"}/>
        </div>
    )
}
