import {BackArrow} from "../../libs/BackArrow";
import {FourSquares} from "./FourSquares";

export function FourSquaresPages() {

    return (
        <>
            <BackArrow/>
            <FourSquares/>
        </>
    )
}
